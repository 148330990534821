import { all } from 'redux-saga/effects'
import { rootGlobalData } from './global'
import { rootDepartementLiveDataByDate } from './departementByDate'
import { rootDepartementLiveAllDataSaga } from './departementAllDate'

export function* rootSaga (){
    yield all([
        rootGlobalData (),
        rootDepartementLiveDataByDate(),
        rootDepartementLiveAllDataSaga()
    ])
}