import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setDepartementData, getDepartementData } from '../Actions/actions'
import { CircularProgress, Select, MenuItem, FormControl } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { departements } from '../assets/departements'

const CovidHistoryDepart = () => {
    const dispatch = useDispatch()
    const isPending = useSelector(state => state.departement?.isPending)
    const data = useSelector(state => state?.departement?.departement?.LiveDataByDepartement)
    const dept = useSelector(state => state?.departement?.Departement)

    const [departement, setDepartement] = useState(departements[0].name)

    const handleChange = (e) => {
        e.preventDefault()
        setDepartement(e.target.value)
        dispatch(setDepartementData(e.target.value))
    }

    useEffect(() => {
        console.log('departement a changé')
        dispatch(getDepartementData())
    }, [departement])
    return (
        <div>
            {isPending ? <CircularProgress /> : data && <div style={{ display: "flex", flexDirection: 'column', alignItems: 'flex-start' }}>
                <h1>Région: 
                <FormControl>
                    <Select
                        labelId="departement-select-label"
                        id="departement-select"
                        value={departement}
                        onChange={handleChange}
                    >
                        {departements.map(i => {
                            return <MenuItem value={i.name} key={i.id}>{i.name}</MenuItem>
                        })}
                    </Select>
                </FormControl>
                </h1>
            <p style={{ padding: '0 0 0px 15px', margin: '0' }}>{`Date: ${data[0].date}`}</p>
            <p style={{ padding: '0 0 0px 15px', margin: '0' }}>{`Source: ${data[0].sourceType}`}</p>
            <p style={{ padding: '0 0 0px 15px', margin: '0' }}>{`Hospitalisés: ${data[0].hospitalises}`}</p>
            <p style={{ padding: '0 0 0px 15px', margin: '0' }}>{`En réanimation: ${data[0].reanimation}`}</p>
            <p style={{ padding: '0 0 0px 15px', margin: '0' }}>{`Nouvelles hosptitalisations: ${data[0].nouvellesHospitalisations}`}</p>
            <p style={{ padding: '0 0 0px 15px', margin: '0' }}>{`Nouvelles réanimations: ${data[0].nouvellesReanimations}`}</p>
            <p style={{ padding: '0 0 0px 15px', margin: '0' }}>{`Décès: ${data[0].deces}`}</p>
            <p style={{ padding: '0 0 0px 15px', margin: '0' }}>{`Guéris: ${data[0].gueris}`}</p>
            </div>}
        </div>
    )
}

export default CovidHistoryDepart