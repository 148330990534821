export const getGlobalData= () => ({type:'getGlobalData'})

export const getGlobalDataSuccess = data => ({
    type:'getGlobalDataSuccess',
    payload:data
})

export const getGlobalDataFailure = error => ({
    type:'getGlobalDataFailure',
    payload:error
})

export const getDepartementData= () => ({type:'getDepartementData'})

export const getDepartementDataSuccess = data => ({
    type:'getDepartementDataSuccess',
    payload:data
})

export const getDepartementDataFailure = error => ({
    type:'getDepartementDataFailure',
    payload:error
})

export const setDepartementData = (dep) => ({
    type:'setDepartementData',
    payload:dep
})

export const getDepartementAllData= () => ({type:'getDepartementAllData'})

export const getDepartementAllDataSuccess = data => ({
    type:'getDepartementAllDataSuccess',
    payload:data
})

export const getDepartementAllDataFailure = error => ({
    type:'getDepartementAllDataFailure',
    payload:error
})

export const setDepartementAllData = (dep) => ({
    type:'setDepartementAllData',
    payload:dep
})