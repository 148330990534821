import { call, put, takeEvery} from '@redux-saga/core/effects'
import {franceGlobalLiveData} from '../api/api'
import { getGlobalData, getGlobalDataSuccess, getGlobalDataFailure} from '../Actions/actions'

export function* rootGlobalData (){
    yield takeEvery ('getGlobalData',getGlobalDataSaga)
}

export function* getGlobalDataSaga() {
    try {
        const data = yield call (franceGlobalLiveData)
        yield put (getGlobalDataSuccess(data))
    }
    catch(err) {
        yield put (getGlobalDataFailure(err))
    }
}