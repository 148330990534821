import { select, call, put, takeEvery} from '@redux-saga/core/effects'
import { franceDepartementLiveAllData } from '../api/api'
import {selectDepartement} from '../Reducers/departementAllData'
import {getDepartementAllData, getDepartementAllDataSuccess, getDepartementAllDataFailure} from '../Actions/actions'


export function* rootDepartementLiveAllDataSaga (){
    yield takeEvery ('getDepartementAllData',getDepartementLiveAllData)
}

export function* getDepartementLiveAllData() {
    const query = yield select (selectDepartement)
    try {
        const data = yield call (franceDepartementLiveAllData,query)
        yield put (getDepartementAllDataSuccess(data))
    }
    catch(err) {
        console.log(err)
        yield put (getDepartementAllDataFailure(err))
    }
}