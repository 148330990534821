export const franceGlobalLiveData = async () => {
    const res = await fetch ('https://coronavirusapi-france.now.sh/FranceLiveGlobalData')
    return res.json()
}

export const franceDepartementLiveData = async (query) => {
    const res = await fetch (`https://coronavirusapi-france.now.sh/LiveDataByDepartement?Departement=${query}`)
    return res.json()
}

export const franceDepartementLiveAllData = async (query) => {
    const res = await fetch (`https://coronavirusapi-france.now.sh/AllDataByDepartement?Departement=${query}`)
    return res.json()
}