const initialState = {departement:undefined,hasErrors:false, isPending:false,Departement:'Ain'}

const departement = (state = initialState, action) => {
    switch (action.type) {
        case 'getDepartementData': return {...state, isPending: true, hasErrors: false}
        case 'getDepartementDataSuccess': return { ...state,departement:action.payload, isPending: false, hasErrors: false}
        case 'getDepartementDataFailure': return { ...state, isPending: false, hasErrors: true}
        case 'setDepartementData': return { ...state, Departement: action.payload}
        default:
            return state
    }
}

export const selectDepartement = state => state.departement.Departement

export default departement