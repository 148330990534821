const initialState = {global:undefined,hasErrors:false, isPending:false,departement:'Ain'}

const global = (state = initialState, action) => {
    switch (action.type) {
        case 'getGlobalData': return {...state, isPending: true, hasErrors: false}
        case 'getGlobalDataSuccess': return { ...state,global:action.payload, isPending: false, hasErrors: false}
        case 'getGlobalDataFailure': return { ...state, isPending: false, hasErrors: true}
        default:
            return state
    }
}

export default global