import React from 'react'
import { CircularProgress } from '@material-ui/core'
import { useSelector } from 'react-redux'

const CovidJour = () => {
    const isPending = useSelector(state => state.global?.isPending)
    const data = useSelector(state => state?.global?.global?.FranceGlobalLiveData)
    const link = "https://solidarites-sante.gouv.fr/"

    return (
        <div>
            {isPending ? <CircularProgress/> : data &&  (<div style={{display:"flex",flexDirection:'column',alignItems:'flex-start'}}>

            <h2 style={{padding:'0 0 0px 15px', margin:'0'}}>{`Données au  ${data[0].date}`}</h2>
                <p style={{padding:'0 0 0px 15px', margin:'0'}}><a href={link}>{`Source: ${data[0].source.nom}`}</a> </p>      
                <p style={{padding:'0 0 0px 15px', margin:'0'}}>{`Nom: ${data[0].nom}`}</p>
                <p style={{padding:'0 0 0px 15px', margin:'0'}}>{`Code: ${data[0].code}`}</p>
                <p style={{padding:'0 0 0px 15px', margin:'0'}}>{`SourceType: ${data[0].sourceType}`}</p>
                <p style={{padding:'0 0 0px 15px', margin:'0'}}>{`Cas confirmés: ${data[0].casConfirmes}`}</p>
                <p style={{padding:'0 0 0px 15px', margin:'0'}}>{`Décès: ${data[0].deces}`}</p>
                <p style={{padding:'0 0 0px 15px', margin:'0'}}>{`Décès dans les Ehpad: ${data[0].decesEhpad}`}</p>
                <p style={{padding:'0 0 0px 15px', margin:'0'}}>{`Hospitalisés: ${data[0].hospitalises}`}</p>
                <p style={{padding:'0 0 0px 15px', margin:'0'}}>{`Réanimation: ${data[0].reanimation}`}</p>
                <p style={{padding:'0 0 0px 15px', margin:'0'}}>{`Guéris: ${data[0].gueris}`}</p>
                <p style={{padding:'0 0 0px 15px', margin:'0'}}>{`Nouvelles hospitalisations: ${data[0].nouvellesHospitalisations}`}</p>
                <p style={{padding:'0 0 0px 15px', margin:'0'}}>{`Nouvelles réanimations: ${data[0].nouvellesReanimations}`}</p>
                <p style={{padding:'0 0 0px 15px', margin:'0'}}>{`SourceType: ${data[0].sourceType}`}</p>
            </div>
            )}
        </div>
    )
}

export default CovidJour