import { select, call, put, takeEvery} from '@redux-saga/core/effects'
import {franceDepartementLiveData} from '../api/api'
import {selectDepartement} from '../Reducers/departement'
import { getDepartementData, getDepartementDataSuccess, getDepartementDataFailure} from '../Actions/actions'

export function* rootDepartementLiveDataByDate (){
    yield takeEvery ('getDepartementData', getDepartementLiveDataByDate)
}

export function* getDepartementLiveDataByDate () {
    const query = yield select (selectDepartement)
    try {
        const data = yield call (franceDepartementLiveData,query)
        yield put (getDepartementDataSuccess(data))
    }
    catch(err) {
        console.log(err)
        yield put (getDepartementDataFailure(err))
    }
}