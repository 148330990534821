const initialState = {departementAllData:undefined,hasErrors:false, isPending:false,Departement:'Ain'}

const departementAllData = (state = initialState, action) => {
    switch (action.type) {
        case 'getDepartementAllData': return {...state, isPending: true, hasErrors: false}
        case 'getDepartementAllDataSuccess': return { ...state,departementAllData:action.payload, isPending: false, hasErrors: false}
        case 'getDepartementAllDataFailure': return { ...state, isPending: false, hasErrors: true}
        case 'setDepartementAllData' : return {...state,Departement:action.payload}
        default:
            return state
    }
}

export const selectDepartement = state => state.departementAllData.Departement

export default departementAllData