import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setDepartementAllData, getDepartementAllData } from '../Actions/actions'
import { CircularProgress, MenuItem, Select, FormControl } from '@material-ui/core'
import { useSelector } from 'react-redux'
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts'

import { departements } from '../assets/departements'

const CovidHistoryByDepAllData = () => {
    const dispatch = useDispatch()
    const isPending = useSelector(state => state.departementAllData?.isPending)
    const data = useSelector(state => state?.departementAllData?.departementAllData?.allDataByDepartement)
    const dept = useSelector(state => state?.departementAllData?.Departement)

    const [departement, setDepartement] = useState(departements[0].name)

    const handleChange = (e) => {
        e.preventDefault()
        setDepartement(e.target.value)
        dispatch(setDepartementAllData(e.target.value))
    }

    useEffect(() => {
        console.log('departement a changé')
        dispatch(getDepartementAllData())
    }, [departement])
    
    return <div>
        {isPending ? <CircularProgress /> : data && (<>
            <h1> {`Région ${dept && dept}`} </h1>
            <FormControl>
                <Select
                    labelId="departement-select-label"
                    id="departement-select"
                    value={departement}
                    onChange={handleChange}
                >
                    {departements.map(i => {
                        return <MenuItem value={i.name} key={i.id}>{i.name}</MenuItem>
                    })}
                </Select>
            </FormControl>
            <LineChart
                width={800}
                height={300}
                data={data}
                margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
            >
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <CartesianGrid strokeDasharray="3 3" />
                <Line dot={false} type="monotone" dataKey="casConfirmes" stroke="#ff7300" />
                <Line dot={false} type="monotone" dataKey="deces" stroke="#387908" />
                <Line dot={false} type="monotone" dataKey="hospitalises" stroke="#380908" />
                <Line dot={false} type="monotone" dataKey="reanimation" stroke="#780108" />
            </LineChart>
        </>
        )}
    </div>
}

export default CovidHistoryByDepAllData