import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getGlobalData, getDepartementData, getDepartementAllData } from './Actions/actions'
import CodidJour from './composants/CovidJour'
import CovidHistoryDepart from './composants/CovidHistoryDepart'
import CovidHistoryByDepAllData from './composants/CovidHistoryByDepAllData'
import './App.css'

function App() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getGlobalData())
    dispatch(getDepartementData())
    dispatch(getDepartementAllData())
  }, [dispatch])

  return (
    <div className="App">
      <header className="App-header">
        <h1>
          Visualisation des données du COVID 19
        </h1>
      </header>
      <div style={{ display: 'flex', Width:'100%' }}>
        <div style={{ Width: '1000px', height: '50vh' ,margin:'15px',}}>
          <CodidJour />
        </div>
        <div style={{ Width: '20%', height: '50vh', margin:'15px' }}>
          <CovidHistoryDepart departement={'Ain'} />
        </div>
        <div style={{ Width: '80%', height: '50vh', margin:'15px'}}>
          <CovidHistoryByDepAllData departement={'Ain'} />
        </div>
      </div>
    </div>
  )
  
}

export default App
