  
import {compose, createStore, applyMiddleware} from 'redux'
import createSagaMiddleware from 'redux-saga'
import allReducers from './Reducers'
import { rootSaga } from './Sagas'

const sagaMiddleware = createSagaMiddleware()

const enhancers = compose( // version pour avoir les dev tools sur chrome et electorn
    applyMiddleware(sagaMiddleware),
    typeof window !== 'undefined' &&  window.__REDUX_DEVTOOLS_EXTENSION__ 
    ? window.__REDUX_DEVTOOLS_EXTENSION__()
    : x => x
)
const store = createStore(allReducers, enhancers)

sagaMiddleware.run(rootSaga)
export default store